




















  import { Vue, Component, Prop } from 'nuxt-property-decorator'
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
  import 'swiper/css/swiper.css'

  @Component({
    components: {
      Swiper,
      SwiperSlide,
    },
  })
  export default class OneCmsCarouselWithThumbnails extends Vue {
    @Prop({
      required: true,
      type: Array,
    })
    readonly slides!: Array<any>

    @Prop({
      required: true,
      type: Boolean,
    })
    readonly autoplay!: boolean

    @Prop({
      required: true,
      type: Number,
    })
    readonly autoplaySpeed!: number

    @Prop({
      required: true,
      type: Boolean,
    })
    readonly showThumbnails!: boolean

    @Prop({
      required: true,
      type: Boolean,
    })
    readonly dots!: boolean

    get shouldShowThumbnails() {
      return this.showThumbnails && !this.dots
    }

    mounted() {
      if (this.shouldShowThumbnails) {
        this.$nextTick(() => {
          // @ts-ignore
          const swiperTop = this.$refs.swiperTop.$swiper
          // @ts-ignore
          const swiperThumbs = this.$refs.swiperThumbs.$swiper
          swiperTop.controller.control = swiperThumbs
          swiperThumbs.controller.control = swiperTop
        })
      }
    }

    get swiperOptions() {
      const defaultSettings: any = {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
      }
      if (!this.dots) {
        defaultSettings.navigation = {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }
      } else {
        defaultSettings.pagination = {
          el: '.swiper-pagination',
        }
      }
      if (!this.showThumbnails && this.autoplay) {
        defaultSettings.autoplay = {
          delay: Number(this.autoplaySpeed),
        }
      }
      return defaultSettings
    }

    get thumbnailsOptions() {
      const defaultSettings: any = {
        loop: true,
        loopedSlides: this.slides.length, // looped slides should be the same
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: 'auto',
        touchRatio: 0.2,
        slideToClickedSlide: true,
      }
      if (this.autoplay) {
        defaultSettings.autoplay = {
          delay: Number(this.autoplaySpeed),
        }
      }
      return defaultSettings
    }
  }
